import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SectionWrapper, DropdownBox, DropboxArrow, DropdownList, DropdownListItem } from './DropdownMobile.styled';

export const DropdownMobile = ({
  navs,
  currentIndex,
  changeMoblieNavIndex,
  dropboxIsClosed,
  changeDropboxIsClosed,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const clickedMobileNavIndex = (index) => {
    setSelectedIndex(index);
    setTimeout(() => {
      setSelectedIndex(-1);
      changeMoblieNavIndex(index);
    }, 300);
  };
  return (
    <SectionWrapper>
      <DropdownBox onClick={changeDropboxIsClosed}>
        {navs[currentIndex]}
        <DropboxArrow rotate={!dropboxIsClosed} name="icon-chevron" />
      </DropdownBox>
      {!dropboxIsClosed && (
        <DropdownList>
          {navs
            // .filter((nav, index) => index !== currentIndex)
            .map((nav, index) =>
              index !== currentIndex ? (
                <DropdownListItem
                  key={index}
                  onClick={() => clickedMobileNavIndex(index)}
                  selected={selectedIndex === index}
                >
                  {nav}
                </DropdownListItem>
              ) : null
            )}
        </DropdownList>
      )}
    </SectionWrapper>
  );
};

DropdownMobile.propTypes = {
  navs: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentIndex: PropTypes.number.isRequired,
  changeMoblieNavIndex: PropTypes.func.isRequired,
  dropboxIsClosed: PropTypes.bool.isRequired,
  changeDropboxIsClosed: PropTypes.func.isRequired,
};
