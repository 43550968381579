import React from 'react';
import styled, { css } from 'styled-components';
import { Container, Vector } from '@fragments';
import { color, spaceMb, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const SectionWrapper = styled(Container)`
  padding: 0;
  margin-top: ${spaceMb(7)};
`;

const DropdownBox = styled.div`
  ${getTypography('heading-3')}
  height: ${toVW(30, 'mobile')};
  padding-left: ${spaceMb(2)};
  padding-right: ${spaceMb(2)};
  margin-bottom: ${spaceMb(1)};
`;

const DropboxArrow = styled((props) => <Vector {...props} />)`
  width: ${toVW(16, 'mobile')};
  height: ${toVW(8, 'mobile')};
  margin-left: ${spaceMb(1.5)};
  transition: 0.2s;

  transform: ${(props) => `rotate(${props.rotate ? '180' : '0'}deg)`};
`;

const DropdownList = styled.div`
  background-color: ${color.accent[1]};
  ${getTypography('heading-4')};
`;

const DropdownListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${spaceMb(2.5)} ${spaceMb(2)} ${spaceMb(2.5)} ${spaceMb(2)};
  background-image: ${(props) =>
    props.selected
      ? css`linear-gradient(to right, ${color.bg.primary} 0%, ${color.bg.primary} 35%, ${color.accent[1]} 35%, ${color.accent[1]} 100%)`
      : 'none'};
  color: ${(props) => (props.selected ? color.text.light : color.text.primary)};
`;

export { SectionWrapper, DropdownBox, DropboxArrow, DropdownList, DropdownListItem };
