import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { TableContainer, TableHead, TableCell, TableBody, TableRow } from './Table.styled';

export const Table = ({ headers, data }) => {
  const memoHeaders = useMemo(() => headers, [headers]);
  const memoData = useMemo(() => data, [data]);

  const numberOfColumns = headers.length + 1;

  const { getTableProps, getTableBodyProps, flatHeaders, rows, prepareRow } = useTable({
    columns: memoHeaders,
    data: memoData,
  });

  return (
    <TableContainer {...getTableProps}>
      <TableHead>
        {flatHeaders.map(({ Header, id }, index) =>
          index !== 0 ? (
            <TableCell key={id} header numberOfColumns={numberOfColumns}>
              {Header}
            </TableCell>
          ) : (
            <TableCell key={id} justify="flex-end" cellSize={2} header numberOfColumns={numberOfColumns}>
              {Header}
            </TableCell>
          )
        )}
      </TableHead>

      <TableBody {...getTableBodyProps}>
        {rows.map((row, rindex) => {
          const isLastRow = rindex === rows.length - 1;
          prepareRow(row);
          return (
            <TableRow key={row.id} {...row.getRowProps()}>
              {row.cells.map((cell, index) =>
                index !== 0 ? (
                  <TableCell
                    key={cell.id}
                    {...cell.getCellProps()}
                    border
                    showAfterBorder={isLastRow}
                    numberOfColumns={numberOfColumns}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                ) : (
                  <TableCell
                    key={cell.id}
                    cellSize={2}
                    justify="flex-start"
                    {...cell.getCellProps()}
                    numberOfColumns={numberOfColumns}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                )
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </TableContainer>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
