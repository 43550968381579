import styled, { css } from 'styled-components';
import { color, spaceMb, spaceDt, screenMin, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const TableContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${color.bg.primary};
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableHead = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableCell = styled.div`
  align-items: center;
  min-width: ${({ cellSize, numberOfColumns }) => {
    const baseSize = (1 / numberOfColumns) * 100;

    return `${cellSize ? cellSize * baseSize : baseSize}%`;
  }};
  position: relative;
  display: inline-flex;
  justify-content: ${({ justify }) => justify || 'center'};
  padding-top: ${spaceMb(6)};
  padding-bottom: ${spaceMb(1.5)};
  border-right: 1px solid transparent;
  color: ${({ header }) => header && color.text.secondary};

  ${screenMin('lg')} {
    padding-top: ${spaceDt(6)};
    padding-bottom: ${spaceDt(1.5)};
  }

  ${({ border }) =>
    border &&
    css`
      &:not(:last-child) {
        border-color: ${color.bg.primary};
      }
    `}

  ${({ showAfterBorder }) =>
    showAfterBorder &&
    css`
      &:not(:last-child) {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          right: -1px;
          width: 1px;
          height: ${toVW(20, 'mobile')};
          background-color: ${color.bg.primary};
          ${screenMin('lg')} {
            height: ${toVW(20, 'desktop')};
          }
        }
      }
    `}
  ${({ header }) => (header ? getTypography('body-2') : getTypography('body-4'))}
`;

export { TableContainer, TableHead, TableCell, TableBody, TableRow };
