import React from 'react';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { TableNavContainer, TableNavItem } from './TableNav.styled';

export const TableNav = ({ navs, currentIndex }) => {
  return (
    <TableNavContainer>
      {navs.map((nav, index) => (
        <TableNavItem key={index} active={currentIndex === index} onClick={() => scrollTo('#'.concat(nav))}>
          {nav}
        </TableNavItem>
      ))}
    </TableNavContainer>
  );
};

TableNav.propTypes = {
  navs: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentIndex: PropTypes.number.isRequired,
};
