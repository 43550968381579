import React from 'react';
import { TextEffect } from '@fragments';
import styled from 'styled-components';
import { spaceMb, getTypography, screenMax } from '@helpers/styles';

const TextBody1 = styled.p`
  ${getTypography('body-1')};
  ${screenMax('lg')} {
    margin-left: ${spaceMb(2)};
  }
`;

const TextBody2 = styled.p`
  ${getTypography('body-2')};
  ${screenMax('lg')} {
    margin-left: ${spaceMb(2)};
  }
`;

export const dataTransparency = {
  seo: {
    title: 'Transparency | Ragnarson',
    description: `We believe that every company can have a positive impact on our society and environment.
    We want to lead by example and here is how we are going to achieve it.`,
  },
  header: {
    name: 'Transparency',
    headline: (
      <>
        The road to more equality and sustainable bussiness is <TextEffect effect="highlight">transparency</TextEffect>.
      </>
    ),
    // TODO: How to get rid of components, and render array of dynamic elements
    content: [
      () => (
        <TextBody1>
          With more transparency comes more responsibility and positive effect on your closest business environment.
          There is no place for cutting the corners, unfair pricing policies, maximising the profit at the expense of
          employees, customers and the environment.
        </TextBody1>
      ),
      () => (
        <TextBody2>
          This data has been always internaly open to all our employees and was giving them a broader context when
          makign decisions.
        </TextBody2>
      ),
      () => (
        <TextBody2>
          Starting in 2020 we are going to be publishing it to the world, to our competitors and customers.
        </TextBody2>
      ),
    ],
  },
};

export const dataTransparencyTables = {
  Impact: {
    id: '1',
    name: 'Impact',
    description: 'Impact',
    columns: [
      {
        Header: 'Year',
        accessor: 'name',
      },
      {
        Header: '2017',
        accessor: 'y2017',
      },
      {
        Header: '2018',
        accessor: 'y2018',
      },
      {
        Header: '2019',
        accessor: 'y2019',
      },
      {
        Header: '2020',
        accessor: 'y2020',
      },
    ],
    rows: [
      {
        name: 'Budget for investments',
        y2017: '0',
        y2018: '0',
        y2019: '$150,000',
        y2020: '0',
      },
      {
        name: 'Budget for investment accumulated',
        y2017: '30%',
        y2018: '30%',
        y2019: '$150,000',
        y2020: '$300,000',
      },
      {
        name: 'Investments',
        y2017: '0',
        y2018: '0',
        y2019: '0',
        y2020: '2',
      },
      {
        name: 'CO2 emmision decrease by remote work',
        y2017: '0',
        y2018: '0',
        y2019: '0',
        y2020: '0',
      },
      {
        name: 'Number of impact clients',
        y2017: '1',
        y2018: '1',
        y2019: '1',
        y2020: '2',
      },
      {
        name: 'CO2 emmision decrease by project',
        y2017: '1, 728 m^3 tones',
        y2018: '1, 728 m^3 tones',
        y2019: '1, 728 m^3 tones',
        y2020: '1, 728 m^3 tones',
      },
    ],
  },
  People: {
    id: '2',
    name: 'People',
    description: 'People',
    columns: [
      {
        Header: 'Year',
        accessor: 'name',
      },
      {
        Header: '2017',
        accessor: 'y2017',
      },
      {
        Header: '2018',
        accessor: 'y2018',
      },
      {
        Header: '2019',
        accessor: 'y2019',
      },
      {
        Header: '2020',
        accessor: 'y2020',
      },
    ],
    rows: [
      {
        name: 'Onboard',
        y2017: '20',
        y2018: '22',
        y2019: '25',
        y2020: '1',
      },
      {
        name: 'Average retention time',
        y2017: '4 years and 3 months',
        y2018: '4 years and 3 months',
        y2019: '4 years and 3 months',
        y2020: '4 years and 3 months',
      },
      {
        name: 'Total worked hours',
        y2017: '34,660 / 100%',
        y2018: '34,660 / 100%',
        y2019: '34,660 / 100%',
        y2020: '34,660 / 100%',
      },
      {
        name: 'Billable hours',
        y2017: '26000 / 74%',
        y2018: '26000 / 74%',
        y2019: '26000 / 74%',
        y2020: '26000 / 74%',
      },
      {
        name: 'Not billable hours',
        y2017: '2300 / 26%',
        y2018: '2300 / 26%',
        y2019: '2300 / 26%',
        y2020: '2300 / 26%',
      },
      {
        name: 'Self development/knowledge sharing hours',
        y2017: '2300 / 5%',
        y2018: '2300 / 5%',
        y2019: '2300 / 5%',
        y2020: '2300 / 5%',
      },
      {
        name: 'Employee benfits spending',
        y2017: '€ 12.755',
        y2018: '€ 12.755',
        y2019: '€ 12.755',
        y2020: '€ 12.755',
      },
    ],
  },
  Customers: {
    id: '3',
    name: 'Customers',
    description: 'Customers',
    columns: [
      {
        Header: 'Year',
        accessor: 'name',
      },
      {
        Header: '2017',
        accessor: 'y2017',
      },
      {
        Header: '2018',
        accessor: 'y2018',
      },
      {
        Header: '2019',
        accessor: 'y2019',
      },
      {
        Header: '2020',
        accessor: 'y2020',
      },
    ],
    rows: [
      {
        name: 'Number of clients',
        y2017: '5',
        y2018: '5',
        y2019: '5',
        y2020: '5',
      },
      {
        name: 'New Clients',
        y2017: '2',
        y2018: '2',
        y2019: '2',
        y2020: '2',
      },
      {
        name: 'Ongoing Clients',
        y2017: '3/70%',
        y2018: '3/70%',
        y2019: '3/70%',
        y2020: '3/70%',
      },
      {
        name: 'Average project lenght',
        y2017: '3,5 years',
        y2018: '3,5 years',
        y2019: '3,5 years',
        y2020: '3,5 years',
      },
    ],
  },
  Finances: {
    id: '4',
    name: 'Finances',
    description: 'Finances',
    columns: [
      {
        Header: 'Year',
        accessor: 'name',
      },
      {
        Header: '2017',
        accessor: 'y2017',
      },
      {
        Header: '2018',
        accessor: 'y2018',
      },
      {
        Header: '2019',
        accessor: 'y2019',
      },
      {
        Header: '2020',
        accessor: 'y2020',
      },
    ],
    rows: [
      {
        name: 'Number of clients',
        y2017: '5',
        y2018: '5',
        y2019: '5',
        y2020: '5',
      },
      {
        name: 'Revenue',
        y2017: '€ 748.134',
        y2018: '€ 937.847',
        y2019: '€ 1.432.121',
        y2020: 'x',
      },
      {
        name: 'Salaries',
        y2017: '€ 561.273',
        y2018: '€ 667.348',
        y2019: '€ 667.348',
        y2020: 'x',
      },
      {
        name: 'Operation Costs',
        y2017: '€ 154.280',
        y2018: '€ 176.791',
        y2019: '€ 215.099',
        y2020: 'x',
      },
      {
        name: 'Taxes',
        y2017: '0',
        y2018: '99.110.00 zł',
        y2019: '264,039.00 zł',
        y2020: 'x',
      },
      {
        name: 'Profit',
        y2017: '€ -26,167',
        y2018: '€ 83.751',
        y2019: '€ 222.670',
        y2020: 'x',
      },
      {
        name: 'Marigin*',
        y2017: '0%',
        y2018: '9%',
        y2019: '15%',
        y2020: 'x',
      },
    ],
  },
  Recruitment: {
    id: '5',
    name: 'Recruitment',
    description: 'Recruitment',
    columns: [
      {
        Header: 'Year',
        accessor: 'name',
      },
      {
        Header: '2017',
        accessor: 'y2017',
      },
      {
        Header: '2018',
        accessor: 'y2018',
      },
      {
        Header: '2019',
        accessor: 'y2019',
      },
      {
        Header: '2020',
        accessor: 'y2020',
      },
    ],
    rows: [
      {
        name: 'Hired',
        y2017: '5',
        y2018: '4',
        y2019: '6',
        y2020: '4',
      },
      {
        name: 'Turnover',
        y2017: '7/23%',
        y2018: '6/12%',
        y2019: '6/32%',
        y2020: '2',
      },
      {
        name: 'Applications',
        y2017: '100',
        y2018: '200',
        y2019: '300',
        y2020: '200',
      },
      {
        name: '% of hiring',
        y2017: '1%',
        y2018: '1%',
        y2019: '1%',
        y2020: '1%',
      },
      {
        name: 'Funnel rekrutacji',
        y2017: '1%',
        y2018: '1%',
        y2019: '1%',
        y2020: '1%',
      },
    ],
  },
};
