import { PageHeader } from '@fragments';
import styled from 'styled-components';
import { spaceDt, screenMin, spaceMb } from '@helpers/styles';
import { InvestmentsGraph } from '@illustrations';

export const StyledPageHeader = styled(PageHeader)`
  margin-bottom: ${spaceMb(8)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(8)};
    padding-left: calc(var(--content-padding-lg-left) * 2);
    padding-right: calc(var(--content-padding-lg-right) * 2);
  }
`;

export const StyledInvestmentsGraph = styled(InvestmentsGraph)`
  margin-bottom: ${spaceMb(8.5)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(17)};
  }
`;
