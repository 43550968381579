import React, { useState, useRef } from 'react';
import { Container, SEO } from '@fragments';
import ContactForm from '@features/ContactForm';
import Slider from 'react-slick';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import styled from 'styled-components';
import { spaceDt, spaceMb } from '@helpers/styles';
import { useStateScreenMobile } from '@helpers/hooks';
import { Table, TableNav, DropdownMobile, TableMobile } from './components';
import { StyledPageHeader, StyledInvestmentsGraph } from './Transparency.styled';
import { dataTransparency, dataTransparencyTables } from './data';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const KPIPageListContainer = styled(Container)`
  margin-bottom: ${(props) => (props.isLast ? spaceDt(12.5) : 0)};
`;

const KPIPageListMobileContainer = styled(Container)`
  margin-bottom: ${spaceMb(10)};
  padding-left: 0;
  padding-right: 0;
`;

const settings = {
  swipe: false,
  adaptiveHeight: true,
  arrows: false,
};

const { header } = dataTransparency;

const Transparency = () => {
  const sliderRef = useRef();
  const [currentMobileNavIndex, setCurrentMobileNavIndex] = useState(0);
  const [dropboxIsClosed, setDropboxIsClosed] = useState(true);

  const changeMoblieNavIndex = (index) => {
    sliderRef.current.slickGoTo(index);
    setCurrentMobileNavIndex(index);
  };

  const changeDropboxIsClosed = () => {
    setDropboxIsClosed(!dropboxIsClosed);
  };

  return (
    <>
      <SEO title={dataTransparency.seo.title} description={dataTransparency.seo.description} />
      {Boolean(header) && <StyledPageHeader name={header.name} headline={header.headline} content={header.content} />}

      <StyledInvestmentsGraph />

      {!useStateScreenMobile() &&
        Object.entries(dataTransparencyTables).map(([prop, val], index) => (
          <KPIPageListContainer
            key={index}
            id={prop}
            isLast={index === Object.entries(dataTransparencyTables).length - 1}
          >
            <TableNav navs={Object.keys(dataTransparencyTables)} currentIndex={index} />
            <Table headers={val.columns} data={val.rows} />
          </KPIPageListContainer>
        ))}

      {useStateScreenMobile() && (
        <KPIPageListMobileContainer>
          <DropdownMobile
            navs={Object.keys(dataTransparencyTables)}
            currentIndex={currentMobileNavIndex}
            changeMoblieNavIndex={changeMoblieNavIndex}
            dropboxIsClosed={dropboxIsClosed}
            changeDropboxIsClosed={changeDropboxIsClosed}
          />
          <Slider ref={sliderRef} {...settings}>
            {Object.entries(dataTransparencyTables).map(([prop, val]) => (
              <TableMobile key={prop} headers={val.columns} data={val.rows} />
            ))}
          </Slider>
        </KPIPageListMobileContainer>
      )}

      <ContactForm headline={<ContactFormHeader>Contact us</ContactFormHeader>} />
    </>
  );
};

export default Transparency;
