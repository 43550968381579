import React from 'react';
import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { spaceMb, getTypography, screenMin } from '@helpers/styles';
import { Container, SEO } from '@fragments';
import { Link } from 'gatsby';
import { Viking404 } from '@illustrations';

const contentData = {
  subheadline: 'We are deeply sorry, but site you are looking for is on another ship.',
  ctaObj: { to: '/', label: 'Go back to homepage', title: 'Go back to homepage' },
};

const {
  subheadline,
  ctaObj: { to, label, title },
} = contentData;

const Section404Picture = styled(Viking404)`
  width: ${toVW(332, 'mobile')};
  height: ${toVW(180, 'mobile')};
  margin: auto;

  ${screenMin('lg')} {
    width: ${toVW(750, 'desktop')};
    height: ${toVW(406, 'desktop')};
  }
`;
const NotFoundHeading = styled.h3`
  margin: 0 auto ${spaceMb(1)};
  ${getTypography('heading-3')}
`;

const Row = styled.div`
  margin: ${spaceMb(2)} auto;
`;

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Not found" />
    <NotFoundHeading>We can&#39;t find the page</NotFoundHeading>
    <Section404Picture />
    <NotFoundHeading>{subheadline}</NotFoundHeading>
    <Row>
      <Link cta="true" to={to} title={title}>
        {label}
      </Link>
    </Row>
  </Container>
);

export default NotFoundPage;
