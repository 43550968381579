import styled, { css } from 'styled-components';
import { color, spaceMb, spaceDt, screenMin, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const TableContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const TableCells = styled.div`
  display: flex;
  flex-direction: row;
  height: ${toVW(48, 'mobile')};
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableHead = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  border-bottom: 1px solid ${color.text.secondary};
  height: ${toVW(48, 'mobile')};
`;

const TableRow = styled.div``;

const TableRowName = styled.div`
  display: flex;
  align-items: center;
  height: ${toVW(48, 'mobile')};
  background-color: ${color.accent[1]};
  padding-left: ${spaceMb(2)};
  ${getTypography('body-1')};
`;

const TableCell = styled.div`
  align-items: center;
  height: ${toVW(48, 'mobile')};
  min-width: ${({ cellSize, numberOfColumns }) => {
    const baseSize = (1 / numberOfColumns) * 100;

    return `${cellSize ? cellSize * baseSize : baseSize}%`;
  }};
  position: relative;
  display: inline-flex;
  justify-content: ${({ justify }) => justify || 'center'};
  color: ${({ header }) => header && color.text.secondary};
  border-bottom: 1px solid ${color.text.secondary};
  text-align: center;
  padding-top: ${spaceMb(1)};
  padding-bottom: ${spaceMb(1)};
  padding-left: ${spaceMb(1)};
  padding-right: ${spaceMb(1)};

  ${screenMin('lg')} {
    padding-top: ${spaceDt(6)};
    padding-bottom: ${spaceDt(1.5)};
  }

  ${({ border }) =>
    border &&
    css`
      &:not(:last-child) {
        border-color: ${color.text.secondary};

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: ${toVW(4, 'mobile')};
          right: -1px;
          width: 1px;
          height: ${toVW(44, 'mobile')};
          background-color: ${color.text.secondary};

          ${screenMin('lg')} {
            height: ${toVW(20, 'desktop')};
          }
        }
      }
    `}

  ${getTypography('body-2')}
`;

export { TableContainer, TableHead, TableCell, TableBody, TableRow, TableCells, TableRowName };
