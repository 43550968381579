import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import {
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableCells,
  TableRowName,
} from './TableMobile.styled';

export const TableMobile = ({ headers, data }) => {
  const memoHeaders = useMemo(() => headers, [headers]);
  const memoData = useMemo(() => data, [data]);

  const numberOfColumns = headers.length - 1;

  const { getTableProps, getTableBodyProps, flatHeaders, rows, prepareRow } = useTable({
    columns: memoHeaders,
    data: memoData,
  });

  return (
    <TableContainer {...getTableProps}>
      <TableHead>
        {flatHeaders.map(({ Header, id }, index) =>
          index !== 0 ? (
            <TableCell key={id} header numberOfColumns={numberOfColumns}>
              {Header}
            </TableCell>
          ) : null
        )}
      </TableHead>

      <TableBody {...getTableBodyProps}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <TableRow key={row.id}>
              <TableRowName>{row.values.name}</TableRowName>
              <TableCells {...row.getRowProps()}>
                {row.cells.map((cell, index) =>
                  index !== 0 ? (
                    <TableCell key={cell.id} {...cell.getCellProps()} border numberOfColumns={numberOfColumns}>
                      {cell.render('Cell')}
                    </TableCell>
                  ) : null
                )}
              </TableCells>
            </TableRow>
          );
        })}
      </TableBody>
    </TableContainer>
  );
};

TableMobile.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
