import styled from 'styled-components';
import { color, spaceDt, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';

const TableNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: ${toVW(90, 'desktop')};
  background-color: ${color.accent[1]};
  margin-top: ${spaceDt(7.5)};
  padding-left: ${spaceDt(13.5)};
  padding-right: ${spaceDt(13.5)};
  padding-top: ${spaceDt(4)};
`;

const TableNavItem = styled.div`
  ${(props) => getTypography(props.active ? 'heading-3' : 'heading-4')};
  text-decoration: ${(props) => (props.active ? 'none' : 'underline')};
  cursor: pointer;
`;

export { TableNavContainer, TableNavItem };
